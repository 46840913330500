body {
    height: 100vh;
    width: 100%;
}
.slide-container {
    background-color: rgb(0, 0, 0, 0.8);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
}

.slide-inner {
    position: relative;
    height: 70%;
    margin: 3% auto;
    width: 90%;
}

.slide-item {
    position: absolute;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    /* margin-top: 50px; */
}

.slide-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbs {
    margin: 15px auto;
    width: 90%;
    position: relative;
}

.thumbs img {
    height: 60px;
    object-fit: cover;
    width: 100%;
}