@keyframes scaleIn {
    0% {
        transform: scale(1);
    }
    /* 50% {
        transform: scale(1.1);
    } */
    100% {
        transform: scale(1.1);
    }
}

.footer-container {
    height: 300px;
    justify-content: center;
    background-color: rgba(38, 94, 158, 1);
    position: relative;
}

.footer-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 60px;
    width: auto;
}

.footer-link {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
    text-align: start;
    text-decoration: none;
    font-size: 14px;
}

.footer-link a {
    /* border-right: 1px white solid; */
    color: white;
    text-decoration: none;
    transition: font-weight 0.5s;
    padding-left: 5%;

}

.footer-link a:hover {
    /* color: rgb(7, 43, 109); */
    font-weight: 900;
}

.footer-social-links {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    justify-content: space-evenly;
    /* height: 40px; */
    width: min-content;
    margin-bottom: 20px;
}

#facebook:hover, #instagram:hover, #tel:hover {
    filter: invert(0.7);
}

button {
    font-size: 9px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: initial;
}

button img {
    object-fit: contain;
    height: 30px;
}

.tel {
    border: 1px white solid;
    border-radius: 10px;
    height: min-content;
    align-self: center;
    font-size: 12px;
    width: max-content;
    padding: 0 15px;
    height: 20px;
}

#tel img {
    height: 13px;
    align-self: center;
}

#facebook, #instagram {
    width: 40px;
    height: 40px;
}

.copyright {
    position: absolute;
    display: inline-flex;
    bottom: 0;
    color: white;
    height: auto;
    text-align: justify;
    width: 70%;
}
.copyright p{
    margin-bottom: 0;
}

.copyright img {
    width: 15px;
    height: 17px;
    object-fit: contain;
}

.footer-container hr {
    border: 1px white solid;
    width: 68%;
}

@media (max-width: 992px) {
    .footer-container hr {
        width: 90%;
    }

    .footer-link {
        text-align: center;
        margin: 30px 0;
        grid-row-gap: 5px;
    }

    .footer-container {
        height: 400px;
    }

    .footer-container img {
        /* justify-self: center; */
        height: auto;
    }
}

@media (max-width: 576px) {
    .copyright {
        width: 100%;
        font-size: 12px;
    }

    .footer-container {
        display: block;
        height: 350px;
    }

    .footer-container .col-xs-12 {
        width: 50%;
        margin: 0 auto;
    }

    .footer-link {
        margin: 30px auto;
        margin-bottom: 5px;
        width: 90%;
        font-size: 12px;
    }

    .footer-social-links {
        margin: 30px auto;
    }
}
