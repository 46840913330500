.articles img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: auto;
    width: auto;
    /* border-top-left-radius: 26px;
    border-top-right-radius: 26px; */
}

.articles {
    color: rgba(104, 109, 126, 1);
    text-align: start;
    height: auto;
    background: url("../../public/news-img.webp") no-repeat top;
    /* background-repeat: no-repeat;
    background-position: top; */
    background-size: contain;
}

.articles button {
    width: 80px;
    height: 25px;
    background-color: rgba(175, 52, 114, 0.8);
    border-radius: 5px;
    margin-bottom: 5%;
    color: #fff;
}

.articles button:hover {
    border: 2px white solid;
    font-weight: bold;
}

.news-date {
    display: inline-flex;
    font-size: 12px;
    column-gap: 3px;
    height: 12px;
    width: max-content;
    margin-top: 64%;
}

.fullNews {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    display: grid;
}

.fullNews img {
    width: 80%;
    height: 350px;
    margin: 20px auto;
    border-radius: 0;
}

/* @media (max-width: 415px) {
    .articles img {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
    }
} */

@media (max-width: 991px) {
    .fullNews img {
        height: 250px;
        width: 90%;
    }
}