@keyframes scaleIn {
    0% {
        transform: scale(1);
    }
    /* 50% {
        transform: scale(1.1);
    } */
    100% {
        transform: scale(1.1);
    }
}
a {
    text-decoration: none;
}

.service-container, .events-container, .news-container {
    width: 60%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    margin: 20px auto;
}

.events-container .events {
    background-color: rgba(247, 248, 252, 1);
}

.services, .events, .articles {
    color: rgb(48, 48, 211);
    text-align: center;
    font-weight: 700;
    border: 1px black solid;
    border-radius: 10%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-content: center;
    padding: 0 5%;
    background-color: white;
    transition: background-color, color 0.2s ease-in-out;
}

.services:hover, .events:hover{
    animation: scaleIn 0.3s ease-in-out both;
    background-color: rgba(175, 52, 114, 0.8);
    color: white
}

.services img {
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 60px;
    width: 60px;
}

.service-title, .events-title, h2{
    width: 100%;
    text-align: center;
    margin: 40px auto 0 auto;
}

.service-title {
    margin-top: 20px;
}

.serviceTitle img {
    object-fit: cover;
    width: 100%;
    height: auto;
}

hr {
    border-top: 3px rgb(56, 55, 55) solid;
    width: 30%;
    align-self: center;
    justify-self: center;
    margin: 10px auto;
}

.service-title hr, .events-title hr {
    width: 10%;
}

.service h1 {
    text-align: center;
}

table, tr, td, th {
    border: 1px solid black;
    text-align: left;
    margin-top: 20px;
}

th {
    text-align: center;
}
tr:nth-child(even) {
    background-color: lightblue;
}
@media (max-width: 992px) {
    .services, .events, .articles {
        height: 220px;
    }

    .service-container, .events-container, .news-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 576px) {
    .service-container, .events-container, .news-container {
        width: 80%;
        padding: 0;
    }
}